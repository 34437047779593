@import url("https://fonts.googleapis.com/css?family=Lato|Open+Sans|Roboto|Roboto+Condensed:300,400,700,900|Roboto+Mono:100,300,400,500,700,900");

html {
  font-size: 16px;
}

* {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-image: none !important;
  background-color: white !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

body {
  color: #65656f;
  background-color: #f2f5f9;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: left;
}

main {
  padding-right: 15px;
  padding-left: 15px;
}

html,
body {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}

svg {
  fill: none;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}
